import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Fade from "react-reveal/Fade"
import Block from "../../components/common/Block/Block.js"
import Buttons from "../../components/common/button.js"
import ColorCards from "../../components/common/Color-card/ColorCards.js"
import CTA from "../../components/common/CTA.js"
import FeatureCards from "../../components/common/Feature-card/FeatureCards.js"
import Frame from "../../components/common/frame.js"
import ScrollCardNavTabs from "../../components/common/Scrolling-container/ScrollCardNavTabs.js"
import SparkCarousal from "../../components/common/SparkCarousal/index.js"
import VerticalTabs from "../../components/common/VerticalTabComponent/VerticalTabs.js"
import Navigation from "../../components/navigation.js"
import "../../styles/pages/msp-25.scss"
import {
  ScatterPlot,
  SliderTestimonial,
  SliderTestimonialCard,
} from "../index.js"

const MSP25 = ({ data }) => {
  const { seo, newField, ctaBoxes } = data.SuperOps.pages[0]
  const heroSection = newField[0]
  const benefitsData = newField[1]
  const itTeamCards = newField[3]
  const faqData = newField[5]
  const testimonialData = newField[6]
  const introImage = newField[11].image[0].url
  const scatterPlot = newField[12]
  const SparkCarousalData = newField[13]
  const blockData = newField[14]
  const miniCards = newField[15]
  const testimonial2 = newField[16]

  return (
    <div className="msp-25">
      <Frame className="outer-container" seo={seo} ogType="website">
        <header>
          <Navigation
            page="Home"
            dskScroll={400}
            darkHelloBar
            theme="dark"
            darkTheme
            btn1="secondary white br-47"
            btn2="primary white ml-9 br-47"
          />
        </header>

        <section className="it-hero-container">
          <div className="hero-section d-flex align-items-center text-center flex-column">
            <div className="content-wrapper Layout-container-big">
              <div className="tag-c down-to-up-1">
                <p className="tag">{heroSection.tag}</p>
              </div>

              <div className="title-wrapper down-to-up-2">
                {parse(heroSection.heroHeading.html)}
              </div>

              <p className="sub-title  down-to-up-3">
                {heroSection.heroContent.text}
              </p>

              <div className="buttons-wrapper">
                <div className="hero-buttons prelative d-flex gap-16 flex-wrap">
                  <Buttons
                    theme="primary-new down-to-up-4"
                    link={heroSection.primaryButtonLink}
                    text={heroSection.primaryButtonText}
                  />
                  <Buttons
                    theme="primary-new white-new  down-to-up-4"
                    link={heroSection.secondaryButtonLink}
                    text={heroSection.secondaryButtonText}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="intro-img-wrapper container w-100 mx-auto  down-to-up-5">
          <img className="w-100" src={introImage} alt="home-intro-image" />
        </section>

        <section className="benefit-cards-parent">
          <div className="Layout-container-big mx-auto">
            <Fade bottom duration={1000}>
              <div className="heading-wrapper">
                {parse(benefitsData.content[0]?.html)}
              </div>
            </Fade>
            <FeatureCards
              title={benefitsData.heading}
              description={benefitsData?.text}
              image={benefitsData?.image}
              linkName={benefitsData?.subtext}
              link={benefitsData?.links}
              cardsPerRow={3}
              showBgColorImgOnHover
            />
          </div>
        </section>

        <VerticalTabs data={faqData} containerClass />

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[0]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            isSecondaryBtn
            newDesign
          />
        </section>

        <div className="Layout-container-big mx-auto it-ops-card-container">
          <Fade bottom duration={1000}>
            <div className="heading">
              {parse(itTeamCards?.content[0]?.html)}
            </div>
          </Fade>

          <ColorCards
            title={itTeamCards?.title}
            description={itTeamCards?.subtext}
            image={itTeamCards?.image}
            color={itTeamCards?.links}
          />
        </div>

        <section className="benefit-cards-mini">
          <div className="Layout-container-big mx-auto">
            <Fade bottom duration={1000}>
              <div className="heading-wrapper">
                {parse(miniCards?.content[0]?.html)}
              </div>
            </Fade>
            <FeatureCards
              title={miniCards?.heading}
              description={miniCards?.text}
              image={miniCards?.image}
              color={miniCards?.links}
              cardsPerRow={3}
            />
          </div>
        </section>

        <section className="slider-testimonial s-t Layout-container-big">
          <SliderTestimonialCard data={testimonial2?.peopleList[0]} showQuote />
        </section>

        <section className="scrolling-tabs-container">
          <ScrollCardNavTabs
            data={newField[7]}
            isPlayOnViewPort
            containerClass
          />
        </section>

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[1]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            newDesign
            isSecondaryBtn
          />
        </section>

        <section className="spark-container Layout-container-big">
          <Fade bottom duration={1000}>
            {parse(newField[13]?.content[0]?.html)}
          </Fade>

          <SparkCarousal
            carousalData={SparkCarousalData}
            sourceType="image"
            fontZodiak
          />
        </section>

        <section className="block-container Layout-container-big">
          <Block
            image={blockData?.blockImage}
            isImage={blockData?.blockIsIllustration}
            isVideo={blockData?.blockIsVideo}
            lgLeft={6}
            lgRight={6}
            heading={blockData?.blockHeading}
            secondaryButtonText={blockData?.linkName}
            secondaryButtonLink={blockData?.linkSlug}
            description={blockData?.blockDescription}
            secondaryBtnTheme="-new"
            containerClass
            showBgColorImg="https://media.graphassets.com/ZaARAtEnSFeb3hpO39h5"
            contentBelowImage
            imageTitle={[""]}
          />
        </section>

        <ScatterPlot
          data={{
            image: scatterPlot.image,
            cardContent: scatterPlot.content,
            cardText: scatterPlot.text,
            cardSubText: scatterPlot.subtext,
            cardLinks: scatterPlot.links,
          }}
        />

        <SliderTestimonial testimonial={testimonialData} />

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[2]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            newDesign
            isSecondaryBtn
          />
        </section>
      </Frame>
    </div>
  )
}

export default MSP25

export const query = graphql`
  query {
    SuperOps {
      pages(where: { title: "MSP-2025" }) {
        title
        linkTitle
        pageImages {
          url
        }
        navigationBlock {
          name
          slug
          isDropdown
        }
        seo {
          title
          description
          keywords
          image {
            url
          }
        }
        navigationBlock {
          colors {
            hex
          }
          where
          dropdownHeader
          navigationLinks {
            heading
            subText
            link
            image {
              url
            }
            childFooter
            childFooterLink
            featureNavigationLinks {
              heading
              subText
              link
              icon {
                url
              }
              iconBgColor {
                css
              }
              childHeader
            }
          }
        }
        ctaBoxes {
          type
          theme
          customBackgroundColor
          heading {
            html
          }
          description {
            html
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          card {
            cardName
            title
            subtext
            slug
          }
        }
        newField {
          ... on SuperOps_Hero {
            lists
            tag
            heroHeading: heading {
              html
            }
            heroContent: content {
              text
              html
            }
            images {
              url
            }
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
          }
          ... on SuperOps_Testimonial {
            peopleList {
              position
              name
              title
              picture {
                url
              }
              cardBgColor
              thumbnail {
                url
              }
            }
            testimonialType
            secondaryBtnText
            secondaryBtnLink
            testimonialTitle
            testimonialImage {
              url
            }
          }
          ... on SuperOps_Card {
            title
            heading
            cardName
            cardDescription
            slug
            links
            text
            content {
              html
            }
            subtext
            image {
              url
            }
          }

          ... on SuperOps_FaqComponent {
            __typename
            faqTitle: title {
              html
            }
            faqHeading: heading
            faqDescription: description
            ctaText {
              text
              html
            }
            faqType
            buttonText: text
            link
            faqs {
              illustrationPattern
              question
              answerrt {
                html
                text
              }
              description
              linkText
              slug
              images {
                url
                fileName
              }
            }
          }

          ... on SuperOps_Block {
            blockTextPosition
            blockHeading
            blockDescription
            blockIsVideo
            blockImage {
              url
            }
            blockIsIllustration
            linkName
            linkSlug
          }
        }
      }
    }
  }
`
